import '../scss/main.scss';
$(document).ready(function() {
  // kv slider
  var slides = $('.kv-slide');
  var currentIndex = 0;
  function showNextSlide() {
      slides.eq(currentIndex).removeClass('active');
      currentIndex = (currentIndex + 1) % slides.length;
      slides.eq(currentIndex).addClass('active');
  }
  setInterval(showNextSlide, 3000);
  setTimeout(function() {
    $('.text-in').addClass('visible');
    setTimeout(function() {
      $('.text-sub-in').addClass('visible');
    }, 1000);
  }, 1000);

  // ハンバーガーメニュー
  const $hamburger = $('.hamburger');
  const $nav = $('.nav');

  $hamburger.off('click');

  $hamburger.on('click', function() {
    $(this).toggleClass('active');
    $nav.toggleClass('active');
    console.log('Hamburger clicked, active class:', $(this).hasClass('active'));
  });

  // header scroll
  const $header = $('header');

  window.addEventListener('scroll', function() {
    let scrollPos = window.scrollY || document.documentElement.scrollTop;
    if (scrollPos > 100) {
      $header.addClass('scrolled');
    } else {
      $header.removeClass('scrolled');
    }
  }, { passive: true });

  // モバイル用
  window.addEventListener('touchmove', function() {
    // 必要に応じて処理を追加
  }, { passive: true });

  // form check
  $('#policy').on('change', function() {
    if ($(this).is(':checked')) {
        $('#submit_button').prop('disabled', false);
        $('.submit_btn').removeClass('disabled');
    } else {
        $('#submit_button').prop('disabled', true);
        $('.submit_btn').addClass('disabled');
    }
  });
});

// スムーススクロール
$('a[href*="#"]').on('click', function(event) {
  var isSamePage = location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
                   && location.hostname === this.hostname;

  if (isSamePage && this.hash) {
    event.preventDefault();

    var target = $(this.hash);
    if (target.length) {
      var offset = 150;
      var position = target.offset().top - offset;
      $('html, body').animate({ scrollTop: position }, 500, 'swing');
    }
  }
});
$(document).ready(function() {
  if (window.location.hash) {
    var target = $(window.location.hash);
    if (target.length) {
      var offset = 150;
      var position = target.offset().top - offset;

      // ページ読み込み後にスムーススクロールを実行
      $('html, body').animate({ scrollTop: position }, 500, 'swing');
    }
  }

  // form check
  const updateSubmitButtonState = () => {
    let allValid = true;

    $('input[type="checkbox"][name="policy-check[]"]').each(function () {
      const isDescribed = $(this).closest('.wpcf7-form-control').attr('aria-describedby');
      const isChecked = $(this).is(':checked');

      if (!isChecked || isDescribed) {
        allValid = false;
        return false;
      }
    });

    if (allValid) {
      $('#submit_button').prop('disabled', false);
      $('.submit_btn').removeClass('disabled');
    } else {
      $('#submit_button').prop('disabled', true);
      $('.submit_btn').addClass('disabled');
    }
  };
  updateSubmitButtonState();
  $(document).on('change', 'input[type="checkbox"][name="policy-check[]"]', function () {
    setTimeout(() => {
      updateSubmitButtonState();
    }, 50);
  });
  $(document).on('wpcf7invalid wpcf7spam wpcf7mailfailed wpcf7mailsent', function () {
    setTimeout(() => {
      updateSubmitButtonState();
    }, 50);
  });

  // そのほかのアニメーション
  $(window).on('scroll', function() {
    $('.flip-in, .under-in, .roll-in').each(function() {
        var elementTop = $(this).offset().top;
        var viewportBottom = $(window).scrollTop() + $(window).height();

        // 要素が画面内に入ったら visible クラスを追加
        if (elementTop < viewportBottom - 50) {
            $(this).addClass('visible');
        }
    });
  });

  $('.wpcf7-list-item-label').on('click', function() {
    // クリックされた .wpcf7-list-item-label の親のチェックボックスをトリガー
    $(this).prev('input[type="checkbox"]').trigger('click');
  });

  // checkbox の選択
  const $check1 = $('#check01 input');
  const $check2 = $('#check02 input');
  $check1.on('change', function() {
      if ($check1.is(':checked')) {
          $check2.prop('checked', false);
      }
  });
  $check2.on('change', function() {
      if ($check2.is(':checked')) {
          $check1.prop('checked', false);
      }
  });
});